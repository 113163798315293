










import { Component, Vue } from 'vue-property-decorator';
import Header from '../components/layouts/Header.vue';
import Footer from '../components/layouts/Footer.vue';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class DefaultLayouts extends Vue {
  get error() {
    return this.$store.getters['window/isNotFound'];
  }
}
